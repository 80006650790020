import React, { Component } from 'react';
import './Footer.css'
import facebook from '../images/facebook-icon.png'
import twitter from '../images/twitter-icon.png'
import youtube from '../images/youtube-icon.png'
import instagram from '../images/instagram-icon.png'
import EmailSignUp from './EmailSignUp';

export class Footer extends Component {
    render() {
        return (
            <div className="pt-2">
                <footer className="footer">
                    <div className="container-fluid" wfd-id="0">
                        <span className="pt-5" wfd-id="1">
                            
                            <p>437 North Street Greenwich, CT 06830<br/>
                            <strong>Phone:</strong> (203) 869-3418<br/>
                            <strong>Fax:</strong> (203) 869-2949<br/>
                            <strong>STORE HOURS:</strong><br/>
                            <strong>Monday - Saturday:-</strong> 8:30am - 6:00pm<br/>
                            <strong>Christmas Eve:</strong> 9:00 – 3:00<br/>
                            <strong>Closed 12/26-1/1/25 Open 1/2/25</strong> CLOSED</p>
                            <p>
                            <a href="https://www.facebook.com/sambridgenursery"><img className="socialicons" src={facebook} alt="facebook icon" /></a>
                            <a href="https://twitter.com/SamBridgeNsy"><img className="socialicons" src={twitter} alt="twitter icon" /></a>
                            <a href="https://www.youtube.com/user/sambridgenursery/videos" ><img className="socialicons" src={youtube} alt="youtube icon"/></a>
                            <a href="https://www.instagram.com/sambridgenursery"><img className="socialicons" src={instagram} alt="instagram icon"/></a>
                            </p>
                        </span>
                        <div className="footersignup"><EmailSignUp/></div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer
